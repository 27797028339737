import { permuteArray } from "@traffic-game/processing-module/helpers/permuteArray";
import { TGGameState, TrafficLightColor as tlc } from "../types";

/**
 * This function does too much and is overly rigid.
 * I didn't want to overengineer it for the initial version of the game,
 * but it will need to be refactored as game complexity increases.
 */
function initializeGameState() {
    const permutations = permuteArray([1, 2, 3]);
    const displayStates = [
        [tlc.RED, tlc.RED, tlc.RED, tlc.RED],
        [tlc.RED, tlc.GREEN, tlc.RED, tlc.GREEN],
        [tlc.GREEN, tlc.RED, tlc.GREEN, tlc.RED],
        [tlc.RED, tlc.GREEN, tlc.GREEN, tlc.RED],
        [tlc.GREEN, tlc.RED, tlc.RED, tlc.GREEN],
        [tlc.GREEN, tlc.GREEN, tlc.GREEN, tlc.GREEN],
    ];
    const shuffledPermutations = permutations.sort(() => Math.random() - 0.5);
    const initialPermutation = shuffledPermutations[0];
    const solutionStates = [shuffledPermutations[1], shuffledPermutations[2]];
    const permutationDisplayPairs = shuffledPermutations.map((perm, index) => ({
        permutation: perm,
        displayState: displayStates[index],
    }));

    const initialGameState: TGGameState = {
        currentState: initialPermutation,
        previousState: null,
        solutionCounter: 0,
    };

    return {
        shuffledPermutations,
        initialGameState,
        permutationDisplayPairs,
        initialPermutation,
        solutionStates,
    };
}

export const {
    shuffledPermutations,
    initialGameState,
    permutationDisplayPairs,
    initialPermutation,
    solutionStates,
} = initializeGameState();
