import { shuffledPermutations } from "./initializeGameState";
import { TGButtonActionType } from "../types";

export function setButtonGroups() {
    const buttonProps = createButtons();
    const buttonGroup = {
        buttonGroup: buttonProps,
    };
    const buttonGroups = [buttonGroup];
    return buttonGroups;
}

function createButtons() {
    const buttons = shuffledPermutations.map((payload, index) => ({
        label: `${index + 1}`,
        actionType: TGButtonActionType.PERMUTE,
        payload: payload,
    }));

    return buttons;
}
