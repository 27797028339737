import multiplyPermutations from "@traffic-game/processing-module/helpers/multiplyPermutations";
import { TGButtonActionType, TGGameState, TGGameStateAction } from "../types";
import {
    initialGameState,
    solutionStates,
} from "../helpers/initializeGameState";

function gameStateReducer(state: TGGameState, action: TGGameStateAction) {
    switch (action.type) {
        case TGButtonActionType.PERMUTE:
            const newCurrentState = multiplyPermutations(
                state.currentState,
                action.payload
            );
            const newPreviousState = state.currentState;
            const currState = JSON.stringify(state.currentState);
            const previousState = JSON.stringify(state.previousState);
            const solState1 = JSON.stringify(solutionStates[0]);
            const solState2 = JSON.stringify(solutionStates[1]);
            const newSolutionCounter =
                currState === solState1 && previousState === solState2
                    ? state.solutionCounter
                    : currState === solState2 && previousState === solState1
                    ? state.solutionCounter + 1
                    : 0;

            const newState: TGGameState = {
                currentState: newCurrentState,
                previousState: newPreviousState,
                solutionCounter: newSolutionCounter,
            };

            if (newSolutionCounter === 3) {
                alert("You win! The game will now reset.");
                return initialGameState;
            }

            return newState;
        case TGButtonActionType.RESET:
            return initialGameState;
        default:
            throw new Error("Invalid action type");
    }
}

export { gameStateReducer };
