import React, { useContext } from "react";
import { TrafficLightGroup } from "./TrafficLightGroup";
import { IntersectionProps, TrafficLightGroupProps } from "../../types";
import { GameStateContext } from "../../GameStateContext";
import { setTrafficLightGroups } from "../../helpers/setTrafficLightGroups";
import "./Intersection.scss";

/**
 * This needs to be reworked to be able to create a game with multiple intersections.
 * Currently, the intersection is a single component in Game.tsx.
 * Expanding to multiple intersections needs to create a new component Intersections that will house all the intersections.
 * The state tracking for trafficLightGroups will need to be moved up to the Intersections component.
 * This Intersection component will then accept a trafficLightGroup as a prop.
 * The setTrafficLightGroups and initializeGameState files will also need to be updated.
 */
export const Intersection: React.FC<IntersectionProps> = () => {
    const gameState = useContext(GameStateContext);
    const trafficLightGroups = setTrafficLightGroups([gameState.currentState]);

    return (
        <section className="intersection">
            {trafficLightGroups.map(
                (
                    trafficLightGroupProps: TrafficLightGroupProps,
                    index: number
                ) => (
                    <TrafficLightGroup
                        key={index}
                        trafficLightGroup={
                            trafficLightGroupProps.trafficLightGroup
                        }
                    />
                )
            )}
        </section>
    );
};
