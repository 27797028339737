interface statusMessages {
    [key: number]: string;
}

const sucessStatusMessageTable: statusMessages = {
    0: "Wow, it seems to be doing the trick!",
    1: "Hey, it's actually getting the job done.",
    2: "Well, that's certainly effective.",
    3: "Impressive, it's doing its job.",
    4: "Look at that, it's functioning well.",
    5: "Hey, that's getting the job done nicely.",
    6: "It appears to be working like a charm.",
    7: "Great, it's operating smoothly.",
    8: "Hey, it's doing what it's supposed to.",
    9: "That's quite efficient, isn't it?",
    10: "Good to see, it's working as intended.",
    11: "Well, it's certainly doing its part.",
    12: "Hey, it's functioning flawlessly.",
    13: "That's working like a well-oiled machine.",
    14: "It's doing wonders, isn't it?",
    15: "Hey, it's getting results.",
    16: "Impressive, it's achieving its purpose.",
    17: "It's working like a dream.",
    18: "Hey, it's hitting the mark.",
    19: "That's working beautifully.",
    20: "Look at that, it's performing admirably.",
    21: "Well, it's certainly doing the job well.",
    22: "Hey, it's functioning to perfection.",
    23: "Great, it's executing the task smoothly.",
    24: "Hey, it's on the right track.",
    25: "That's operating seamlessly.",
    26: "It's doing exactly what it should.",
    27: "Good to see, it's achieving its goals.",
    28: "Hey, it's delivering the goods.",
    29: "That's working like a well-tuned instrument.",
    30: "It's making a positive impact.",
    31: "Hey, it's generating the desired results.",
    32: "Impressive, it's operating with precision.",
    33: "It's functioning at its best.",
    34: "Hey, it's carrying out its function.",
    35: "That's working perfectly.",
    36: "Look at that, it's functioning flawlessly.",
    37: "Well, it's certainly fulfilling its role.",
    38: "Hey, it's working without a hitch.",
    39: "Great, it's performing as expected.",
    40: "Hey, it's producing the desired outcomes.",
    41: "That's operating smoothly.",
    42: "It's doing the job admirably.",
    43: "Hey, it's achieving its intended purpose.",
    44: "It's working like a well-coordinated team.",
    45: "Hey, it's getting the job done effectively.",
    46: "Impressive, it's running like clockwork.",
    47: "It's doing precisely what it should.",
    48: "Good to see, it's functioning efficiently.",
    49: "Hey, it's succeeding in its mission.",
    50: "Wow, it seems to be doing the trick!",
    51: "Hey, it's actually getting the job done.",
    52: "Well, that's certainly effective.",
    53: "Impressive, it's doing its job.",
    54: "Look at that, it's functioning well.",
    55: "Hey, that's getting the job done nicely.",
    56: "It appears to be working like a charm.",
    57: "Great, it's operating smoothly.",
    58: "Hey, it's doing what it's supposed to.",
    59: "That's quite efficient, isn't it?",
    60: "Good to see, it's working as intended.",
    61: "Well, it's certainly doing its part.",
    62: "Hey, it's functioning flawlessly.",
    63: "That's working like a well-oiled machine.",
    64: "It's doing wonders, isn't it?",
    65: "Hey, it's getting results.",
    66: "Impressive, it's achieving its purpose.",
    67: "It's working like a dream.",
    68: "Hey, it's hitting the mark.",
    69: "That's working beautifully.",
    70: "Look at that, it's performing admirably.",
    71: "Well, it's certainly doing the job well.",
    72: "Hey, it's functioning to perfection.",
    73: "Great, it's executing the task smoothly.",
    74: "Hey, it's on the right track.",
    75: "That's operating seamlessly.",
    76: "It's doing exactly what it should.",
    77: "Good to see, it's achieving its goals.",
    78: "Hey, it's delivering the goods.",
    79: "That's working like a well-tuned instrument.",
    80: "It's making a positive impact.",
    81: "Hey, it's generating the desired results.",
    82: "Impressive, it's operating with precision.",
    83: "It's functioning at its best.",
    84: "Hey, it's carrying out its function.",
    85: "That's working perfectly.",
    86: "Look at that, it's functioning flawlessly.",
    87: "Well, it's certainly fulfilling its role.",
    88: "Hey, it's working without a hitch.",
    89: "Great, it's performing as expected.",
    90: "Hey, it's producing the desired outcomes.",
    91: "That's operating smoothly.",
    92: "It's doing the job admirably.",
    93: "Hey, it's achieving its intended purpose.",
    94: "It's working like a well-coordinated team.",
    95: "Hey, it's getting the job done effectively.",
    96: "Impressive, it's running like clockwork.",
    97: "It's doing precisely what it should.",
    98: "Good to see, it's functioning efficiently.",
    99: "Hey, it's succeeding in its mission.",
};

const failureStatusMessageTable: statusMessages = {
    0: "No, that's incorrect.",
    1: "That's not quite right.",
    2: "I'm afraid that's not the answer.",
    3: "Nope, that's not the right one.",
    4: "That's not what I had in mind.",
    5: "No, that's not the solution.",
    6: "Sorry, but that's not accurate.",
    7: "That's not the correct choice.",
    8: "I think you're mistaken, that's not it.",
    9: "No, that's not what I was looking for.",
    10: "That's not the appropriate response.",
    11: "I disagree, that's not it.",
    12: "Sorry, but that's not the right answer.",
    13: "That's not the way to go.",
    14: "No, that's not the key.",
    15: "That's not on the mark.",
    16: "I'm sorry, but that's not the right direction.",
    17: "That's not the solution I had in mind.",
    18: "No, that's not the remedy.",
    19: "Sorry, but that's not the winning choice.",
    20: "That's not the accurate response.",
    21: "I beg to differ; that's not it.",
    22: "No, that's not the appropriate choice.",
    23: "That's not what I was aiming for.",
    24: "I'm afraid you're mistaken; that's not it.",
    25: "No, that's not the answer I had in mind.",
    26: "That's not the way to solve it.",
    27: "I disagree; that's not the correct one.",
    28: "Sorry, but that's not the right approach.",
    29: "That's not the right tactic.",
    30: "No, that's not the right path.",
    31: "That's not the remedy for this situation.",
    32: "I'm sorry, but that's not the solution.",
    33: "That's not the right strategy.",
    34: "No, that's not the right procedure.",
    35: "Sorry, but that's not the best option.",
    36: "That's not the solution I was thinking of.",
    37: "No, that's not what I was going for.",
    38: "That's not the most suitable response.",
    39: "I'm afraid that's not the correct one.",
    40: "No, that's not the way to handle it.",
    41: "That's not the right course of action.",
    42: "I disagree; that's not it at all.",
    43: "Sorry, but that's not the right way to go.",
    44: "That's not the solution that works here.",
    45: "No, that's not what's needed.",
    46: "Sorry, but that's not the right way to approach it.",
    47: "That's not the right way to address this.",
    48: "I disagree; that's not the right fit.",
    49: "No, that's not the appropriate choice.",
};

export const getRandomSuccessMessage = () => {
    const randomIndex = Math.floor(
        Math.random() * Object.keys(sucessStatusMessageTable).length
    );
    return sucessStatusMessageTable[randomIndex];
};

export const getRandomFailureMessage = () => {
    const randomIndex = Math.floor(
        Math.random() * Object.keys(failureStatusMessageTable).length
    );
    return failureStatusMessageTable[randomIndex];
};
