import { TrafficLightGroupProps } from "../types";
import { permutationDisplayPairs } from "./initializeGameState";

// Update logic required for multiple intersections.
export function setTrafficLightGroups(permutations: number[][]) {
    const trafficLightGroups: TrafficLightGroupProps[] = [];
    permutations.forEach((permutation) => {
        const currentDisplayState = permutationDisplayPairs.find(
            (pair) =>
                JSON.stringify(pair.permutation) === JSON.stringify(permutation)
        );

        if (!currentDisplayState) {
            throw new Error("Invalid State");
        }

        const trafficLightGroup = currentDisplayState.displayState.map(
            (color) => ({
                color: color,
            })
        );
        trafficLightGroups.push({ trafficLightGroup });
    });
    return trafficLightGroups;
}
