import React from "react";
import {
    TGControlButtonContainerProps,
    TGControlButtonGroupProps,
} from "../../types";
import { TGControlButtonGroup } from "./TGControlButtonGroup";
import { setButtonGroups } from "../../helpers/setButtonGroups";
import "./TGControlButtonContainer.scss";

export const TGControlButtonContainer: React.FC<
    TGControlButtonContainerProps
> = () => {
    const buttonGroups = setButtonGroups();
    return (
        <section className="tg-button-container">
            {buttonGroups.map(
                (
                    buttonGroupProps: TGControlButtonGroupProps,
                    index: number
                ) => (
                    <TGControlButtonGroup
                        key={index}
                        buttonGroup={buttonGroupProps.buttonGroup}
                    />
                )
            )}
        </section>
    );
};
