import React from "react";
import { TGControlButtonGroupProps, TGControlButtonProps } from "../../types";
import { TGControlButton } from "./TGControlButton";
import "./TGControlButtonGroup.scss";

export const TGControlButtonGroup: React.FC<TGControlButtonGroupProps> = ({
    buttonGroup,
}) => {
    return (
        <div className="tg-button-group">
            {buttonGroup.map(
                (buttonProps: TGControlButtonProps, index: number) => (
                    <TGControlButton
                        // To do: add key to TGControlButton. maybe just composite actionType and payload?
                        key={index}
                        label={buttonProps.label}
                        actionType={buttonProps.actionType}
                        payload={buttonProps.payload}
                    />
                )
            )}
        </div>
    );
};
