import { TGControlButtonContainer } from "./Control/TGControlButtonContainer";
import { Intersection } from "./Display/Intersection";
import {
    GameStateContext,
    GameStateDispatchContext,
} from "../GameStateContext";
import { useReducer } from "react";
import { gameStateReducer } from "../reducers/gameStateReducer";
import { initialGameState } from "../helpers/initializeGameState";
import "./Game.scss";
import { StatusPanel } from "./Display/StatusPanel";
import { HomepageLink } from "./Display/HomepageLink";

export const Game = () => {
    const [gameState, dispatch] = useReducer(
        gameStateReducer,
        initialGameState
    );

    return (
        <GameStateContext.Provider value={gameState}>
            <GameStateDispatchContext.Provider value={dispatch}>
                <div className="tg-game">
                    <HomepageLink />
                    <StatusPanel />
                    <Intersection />
                    <TGControlButtonContainer />
                </div>
            </GameStateDispatchContext.Provider>
        </GameStateContext.Provider>
    );
};
