import React, { useContext } from "react";
import { TGControlButtonProps, TGGameStateAction } from "../../types";
import { GameStateDispatchContext } from "../../GameStateContext";
import "./TGControlButton.scss";

export const TGControlButton: React.FC<TGControlButtonProps> = ({
    label,
    actionType,
    payload,
}) => {
    const dispatch = useContext(GameStateDispatchContext);
    const action: TGGameStateAction = {
        type: actionType,
        payload: payload,
    };
    const handleButtonClick = () => {
        dispatch(action);
    };

    return (
        <button
            className="tg-control-button"
            onClick={handleButtonClick}
        >
            {label}
        </button>
    );
};
