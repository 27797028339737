import { useContext } from "react";
import { GameStateContext } from "../../GameStateContext";
import "./StatusText.scss";
import {
    getRandomFailureMessage,
    getRandomSuccessMessage,
} from "../../helpers/constants/statusMessages";

export const StatusText = () => {
    const gameState = useContext(GameStateContext);
    let message = "";
    switch (gameState.solutionCounter) {
        case 0:
            if (gameState.previousState === null) {
                message =
                    "Use the buttons to control the traffic signals. Try to match the goal pattern here.";
            } else {
                message = getRandomFailureMessage();
            }
            break;
        case 1:
            message = getRandomSuccessMessage();
            break;
        case 2:
            message = getRandomSuccessMessage();
            break;
        default:
            message = "You did it! Great job!";
    }

    return <p className="status-text">{message}</p>;
};
