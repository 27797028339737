import React from "react";
import { TrafficLightProps } from "../../types";
import "./TrafficLight.scss";

export const TrafficLight: React.FC<TrafficLightProps> = ({ color }) => {
    /**
     * This could get turned into a normal 3 light traffic light.
     * Extract the circle into a TrafficLightSignal component.
     */
    return (
        <svg className="tg-traffic-light">
            <circle
                className="traffic-light-signal"
                cx="50%"
                cy="50%"
                r="40%"
                stroke="black"
                stroke-width="3"
                fill={color}
            />
        </svg>
    );
};
