export const permuteArray = (arr: number[]): number[][] => {
    if (arr.length === 1) {
        return [arr];
    }
    const result: number[][] = [];
    for (let i = 0; i < arr.length; i++) {
        const rest = [...arr.slice(0, i), ...arr.slice(i + 1)];
        const restPermutations = permuteArray(rest);
        for (const permutation of restPermutations) {
            result.push([arr[i], ...permutation]);
        }
    }
    return result;
};
