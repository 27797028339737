import React from "react";
import { TrafficLight } from "./TrafficLight";
import { TrafficLightProps, TrafficLightGroupProps } from "../../types";
import "./TrafficLightGroup.scss";

export const TrafficLightGroup: React.FC<TrafficLightGroupProps> = ({
    trafficLightGroup,
}) => {
    return (
        <div className="traffic-light-group">
            {trafficLightGroup.map(
                (trafficLightProps: TrafficLightProps, index: number) => (
                    // To do: add key to TrafficLight (Maybe a N/S/W/E position enum?)
                    <TrafficLight
                        key={index}
                        color={trafficLightProps.color}
                    />
                )
            )}
        </div>
    );
};
