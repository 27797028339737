import { StatusText } from "./StatusText";
import "./StatusPanel.scss";
import { SolutionPreview } from "./SolutionPreview";

/**
 *  The styling for this component is sloppy and should be completely redone.
 *  It should be able to handle a variable solution length.
 */
export const StatusPanel = () => {
    return (
        <section className="status-panel">
            <StatusText />
            <SolutionPreview />
        </section>
    );
};
