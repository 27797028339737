import React from "react";
import { TrafficLightGroup } from "./TrafficLightGroup";
import { IntersectionProps, TrafficLightGroupProps } from "../../types";
import { setTrafficLightGroups } from "../../helpers/setTrafficLightGroups";
import { solutionStates } from "../../helpers/initializeGameState";
import "./SolutionPreview.scss";
import "./StatusText.scss";

export const SolutionPreview: React.FC<IntersectionProps> = () => {
    const trafficLightGroups = setTrafficLightGroups(solutionStates);

    return (
        <>
            <p className="status-text">Goal pattern:</p>
            <div className="solution-preview">
                {trafficLightGroups.map(
                    (
                        trafficLightGroupProps: TrafficLightGroupProps,
                        index: number
                    ) => (
                        <TrafficLightGroup
                            key={index}
                            trafficLightGroup={
                                trafficLightGroupProps.trafficLightGroup
                            }
                        />
                    )
                )}
            </div>
        </>
    );
};
