import { ControlButtonProps } from "@traffic-game/control-module/types";
import { GameState } from "@traffic-game/processing-module/types";

// Control
export interface TGControlButtonProps extends ControlButtonProps {
    label: string;
    actionType: TGButtonActionType;
    payload: number[];
}

export interface TGControlButtonGroupProps {
    buttonGroup: TGControlButtonProps[];
}

export interface TGControlButtonContainerProps {}

export enum TGButtonActionType {
    PERMUTE = "permute",
    RESET = "reset",
}

// Display
export enum TrafficLightColor {
    RED = "red",
    YELLOW = "yellow",
    GREEN = "green",
}

export interface TrafficLightProps {
    color: TrafficLightColor;
}

export interface TrafficLightGroupProps {
    trafficLightGroup: TrafficLightProps[];
}

export interface IntersectionProps {}

// Processing
export interface TGGameState extends GameState {
    currentState: number[];
    previousState: number[] | null;
    solutionCounter: number;
}

export interface TGGameStateAction {
    type: TGButtonActionType;
    payload: number[];
}
