import React from "react";
import "./App.scss";
import { Game } from "./components/Game";

function App() {
    return (
        <body>
            <Game />
        </body>
    );
}

export default App;
